@charset "utf-8";
@import '~bulma/bulma.sass';

@media screen and (max-width: 768px) and (orientation: landscape) {
  .custom-is-fullheight {
    height: 100vw !important;
    min-height: 0 !important;
  }
}

@media screen and (min-width: $tablet) {
  .custom-is-fullheight {
    height: 85vh !important;
  }
}
